import { Controller } from "@hotwired/stimulus";
import * as d3 from "d3";

// Connects to data-controller="pie-chart"
export default class extends Controller {
  static values = { data: Array };

  #d3ViewWidth = 200;
  #d3ViewHeight = 200;

  connect() {
    this.#draw();
  }

  #draw() {
    const color = d3.scaleOrdinal(
      d3.quantize(d3.interpolateRainbow, this.dataValue.length + 1)
    );
    const pie = d3.pie().value((d) => Math.abs(d.value));
    const arc = d3
      .arc()
      .innerRadius(this.#radius * 0.618)
      .outerRadius(this.#radius * 0.9);

    const svg = this.#d3container
      .append("svg")
      .attr("width", "100%")
      .attr("class", "relative aspect-square")
      .attr("viewBox", `0 0 ${this.#d3ViewWidth} ${this.#d3ViewHeight}`)
      .append("g")
      .attr(
        "transform",
        `translate(${this.#d3ViewWidth / 2},${this.#d3ViewHeight / 2})`
      );

    const arcs = svg
      .selectAll(".arc")
      .data(pie(this.dataValue))
      .enter()
      .append("g")
      .attr("class", "arc");

    const path = arcs
      .append("path")
      .attr("d", arc)
      .attr("fill", (d) => color(d.data.name));

    path
      .on("mouseover", (event, d) => {
        // Create tooltip if it doesn't exist
        if (!this.tooltip) {
          this.tooltip = d3
            .select(this.element)
            .append("div")
            .attr("class", "p-2 bg-dark text-white small rounded shadow")
            .style("pointer-events", "none");
        }

        this.tooltip
          .html(`${d.data.name}: ${d.data.format}`)
          .classed("hidden", false)
          .style("left", event.pageX + 10 + "px")
          .style("top", event.pageY + 10 + "px");

        d3.select(event.target)
          .transition()
          .duration(200)
          .attr(
            "d",
            d3
              .arc()
              .innerRadius(this.#radius * 0.618)
              .outerRadius(this.#radius * 0.92)
          );
      })
      .on("mouseout", (event, d) => {
        // Hide the tooltip
        this.tooltip.classed("hidden", true);

        d3.select(event.target)
          .transition()
          .duration(200)
          .attr(
            "d",
            d3
              .arc()
              .innerRadius(this.#radius * 0.618)
              .outerRadius(this.#radius * 0.9)
          );
      });
  }

  get #radius() {
    return Math.min(this.#d3ViewWidth, this.#d3ViewHeight) / 2;
  }

  get #d3container() {
    return d3.select(this.element);
  }
}
