import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "expense",
    "income",
    "transfer",
    "fromAccount",
    "toAccount"
  ];
  static classes = ["tabActive"];

  #assets = [];
  #liabilities = [];
  #expenses = [];
  #incomes = [];

  connect() {
    let nature = document.querySelector("input[name='journal[nature]']").value;
    const accounts = JSON.parse(this.element.dataset.accounts);
    this.#assets = accounts["Accounts::Asset"];
    this.#liabilities = accounts["Accounts::Liability"];
    this.#expenses = this.#groupAccounts(accounts["Accounts::Expense"]);
    this.#incomes = accounts["Accounts::Income"];

    this.#activateTab(nature);
    this.#updateSelections(nature);
  }

  updateNature(event) {
    event.preventDefault();
    const nature = event.currentTarget.dataset.nature;

    this.#activateTab(nature);
    this.#updateSelections(nature);
    document.querySelector("input[name='journal[nature]']").value = nature;
  }

  #groupAccounts(accounts) {
    const groupedAccounts = {};
    const rootAccounts = [];

    // First pass: Create all account objects
    accounts.forEach((account) => {
      groupedAccounts[account.id] = { ...account, children: [] };
    });

    // Second pass: Build the hierarchy
    accounts.forEach((account) => {
      if (account.parent_id === null) {
        rootAccounts.push(groupedAccounts[account.id]);
      } else {
        groupedAccounts[account.parent_id].children.push(
          groupedAccounts[account.id]
        );
      }
    });

    return rootAccounts;
  }

  #createOptions(accounts, depth = 0) {
    return accounts
      .map((account) => {
        const indent = "&nbsp;".repeat(depth * 2);
        let options = "";
        if (depth > 0) {
          options = `<option value="${account.id}">${indent}${account.name}</option>`;
        } else {
          options =
            account.children && account.children.length > 0
              ? `<option value="" disabled>${account.name}</option>`
              : `<option value="${account.id}">${account.name}</option>`;
        }
        if (account.children && account.children.length > 0) {
          options += this.#createOptions(account.children, depth + 1);
        }
        return options;
      })
      .join("");
  }

  #updateSelections(nature) {
    const accountOptions = {
      expense: {
        from: this.#assets.concat(this.#liabilities),
        to: this.#expenses
      },
      income: {
        from: this.#incomes,
        to: this.#assets.concat(this.#liabilities)
      },
      transfer: {
        from: this.#assets.concat(this.#liabilities),
        to: this.#assets.concat(this.#liabilities)
      }
    };

    if (accountOptions[nature]) {
      this.fromAccountTarget.innerHTML = this.#createOptions(
        accountOptions[nature].from
      );
      this.toAccountTarget.innerHTML = this.#createOptions(
        accountOptions[nature].to
      );
    }
  }

  #activateTab(nature) {
    const _targets = ["expense", "income", "transfer"];
    _targets.forEach((target) => {
      const element = this[`${target}Target`];
      if (target === nature) {
        element.classList.add(this.tabActiveClass);
      } else {
        element.classList.remove(this.tabActiveClass);
      }
    });
  }
}
