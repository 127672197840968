import { Controller } from "@hotwired/stimulus";
import * as d3 from "d3";

// Connects to data-controller="multi-pie-chart"
export default class extends Controller {
  static values = { data: Array };

  #d3ViewWidth = 200;
  #d3ViewHeight = 200;

  connect() {
    this.#draw();
  }

  #draw() {
    const arcVisible = (d) => {
      return d.y1 <= 3 && d.y0 >= 1 && d.x1 > d.x0;
    };

    const labelVisible = (d) => {
      return d.y1 <= 3 && d.y0 >= 1 && (d.y1 - d.y0) * (d.x1 - d.x0) > 0.03;
    };

    const labelTransform = (d) => {
      const x = (((d.x0 + d.x1) / 2) * 180) / Math.PI;
      const y = ((d.y0 + d.y1) / 2) * this.#radius;
      return `rotate(${x - 90}) translate(${y},0) rotate(${x < 180 ? 0 : 180})`;
    };
    const color = d3.scaleOrdinal(
      d3.quantize(d3.interpolateRainbow, this.dataValue.length + 1)
    );
    const hierarchy = d3
      .hierarchy({ children: this.dataValue })
      .sum((d) => d.value)
      .sort((a, b) => b.value - a.value);

    const root = d3.partition().size([2 * Math.PI, hierarchy.height + 1])(
      hierarchy
    );
    root.each((d) => (d.current = d));

    const arc = d3
      .arc()
      .startAngle((d) => d.x0)
      .endAngle((d) => d.x1)
      .padAngle((d) => Math.min((d.x1 - d.x0) / 2, 0.005))
      .padRadius(this.#radius * 1.5)
      .innerRadius((d) => d.y0 * this.#radius)
      .outerRadius((d) =>
        Math.max(d.y0 * this.#radius, d.y1 * this.#radius - 1)
      );

    const svg = this.#d3container
      .append("svg")
      .attr("viewBox", [
        -this.#d3ViewWidth / 2,
        -this.#d3ViewHeight / 2,
        this.#d3ViewWidth,
        this.#d3ViewHeight
      ])
      .style("font", "10px sans-serif");

    const path = svg
      .append("g")
      .selectAll("path")
      .data(root.descendants().slice(1))
      .join("path")
      .attr("fill", (d) => {
        while (d.depth > 1) d = d.parent;
        return color(d.data.name);
      })
      .attr("fill-opacity", (d) =>
        arcVisible(d.current) ? (d.children ? 0.6 : 0.4) : 0
      )
      .attr("pointer-events", (d) => (arcVisible(d.current) ? "auto" : "none"))
      .attr("d", (d) => arc(d.current));

    const label = svg
      .append("g")
      .attr("pointer-events", "none")
      .attr("text-anchor", "middle")
      .style("user-select", "none")
      .selectAll("text")
      .data(root.descendants().slice(1))
      .join("text")
      .attr("dy", "0.35em")
      .attr("fill-opacity", (d) => +labelVisible(d.current))
      .attr("transform", (d) => labelTransform(d.current))
      .text((d) => d.data.name);

    const clicked = (event, p) => {
      parent.datum(p.parent || root);

      root.each(
        (d) =>
          (d.target = {
            x0:
              Math.max(0, Math.min(1, (d.x0 - p.x0) / (p.x1 - p.x0))) *
              2 *
              Math.PI,
            x1:
              Math.max(0, Math.min(1, (d.x1 - p.x0) / (p.x1 - p.x0))) *
              2 *
              Math.PI,
            y0: Math.max(0, d.y0 - p.depth),
            y1: Math.max(0, d.y1 - p.depth)
          })
      );

      const t = svg.transition().duration(750);

      // Transition the data on all arcs, even the ones that aren’t visible,
      // so that if this transition is interrupted, entering arcs will start
      // the next transition from the desired position.
      path
        .transition(t)
        .tween("data", (d) => {
          const i = d3.interpolate(d.current, d.target);
          return (t) => (d.current = i(t));
        })
        .filter(function (d) {
          return +this.getAttribute("fill-opacity") || arcVisible(d.target);
        })
        .attr("fill-opacity", (d) =>
          arcVisible(d.target) ? (d.children ? 0.6 : 0.4) : 0
        )
        .attr("pointer-events", (d) => (arcVisible(d.target) ? "auto" : "none"))

        .attrTween("d", (d) => () => arc(d.current));

      label
        .filter(function (d) {
          return +this.getAttribute("fill-opacity") || labelVisible(d.target);
        })
        .transition(t)
        .attr("fill-opacity", (d) => +labelVisible(d.target))
        .attrTween("transform", (d) => () => labelTransform(d.current));
    };
    path
      .filter((d) => d.children)
      .style("cursor", "pointer")
      .on("click", clicked);

    const format = d3.format(",d");
    path.append("title").text(
      (d) =>
        `${d
          .ancestors()
          .map((d) => d.data.name)
          .reverse()
          .join("/")}\n${format(d.value)}`
    );
    const parent = svg
      .append("circle")
      .datum(root)
      .attr("r", this.#radius)
      .attr("fill", "none")
      .attr("pointer-events", "all")
      .on("click", clicked);
  }

  get #radius() {
    return Math.min(this.#d3ViewWidth, this.#d3ViewHeight) / 6;
  }

  get #d3container() {
    return d3.select(this.element);
  }
}
