import { Controller } from "@hotwired/stimulus";
import { Modal } from "bootstrap";

export default class extends Controller {
  static values = {
    open: { type: Boolean, default: false }
  };

  #modal;

  initialize() {
    this.#modal = new Modal(this.element, {
      backdrop: "static",
      keyboard: false
    });

    this.element.addEventListener("hidden.bs.modal", () => {
      this.element.remove();
      // Remove the modal-backdrop element
      const modalBackdrop = document.querySelector(".modal-backdrop");
      if (modalBackdrop) {
        modalBackdrop.remove();
      }
    });
  }

  connect() {
    this.#modal = new Modal(this.element, {
      backdrop: "static",
      keyboard: false
    });
    if (this.openValue) {
      this.open();
    }
  }

  disconnect() {
    this.#modal.dispose();
  }

  open() {
    this.#modal.show();
  }

  close() {
    this.#modal.hide();
  }

  openValueChanged() {
    if (this.openValue) {
      this.open();
    } else {
      this.close();
    }
  }

  clickOutside(event) {
    if (event.target === this.element) {
      this.close();
    }
  }
}
